<template>
	<div>
	    <b-table small :fields="fields_active" :items="active" responsive="lg" tbody-tr-class="size-14 table-row" @row-clicked="myRowClickHandler" @head-clicked="headClicked" id="overview-table" class="mb-3 cursor-pointer" show-empty>

	    	<template #table-caption>Projects Overview</template>
	    	<template #head(country)="data">
		        <span>{{ data.label }}</span>
		        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 30px;">
		        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
		        </span>
		    </template>
		    <template #head()="data">
	    		<span class="d-block text-center">
			        <span>{{ data.label }}</span>
			        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 30px;">
			        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
			        </span>
			    </span>
		    </template>
			<template #cell(country)="data">
				<span class="d-block text-left">{{ data.item.name }}</span>
			</template>
			<template #cell(active)="data">
				<span class="d-block text-center">{{ data.item.total_active }}</span>
			</template>
			<template #cell(completed)="data">
				<span class="d-block text-center">{{ data.item.total_completed }}</span>
			</template>
			<template #cell(developing)="data">
				<span class="d-block text-center">{{ data.item.total_developing }}</span>
			</template>
			<template #cell(terminated)="data">
				<span class="d-block text-center">{{ data.item.total_terminated }}</span>
			</template>
			<template #cell()="data">
				<span class="d-block text-center">{{ data.value }}</span>
			</template>
			<template #empty="scope">
				<template v-if="search">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
				</template>
				<template v-else>
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
		    </template>	    
		</b-table>
		<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="overview-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select name="" id="" v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>		
		    </div>
		</div>
	</div>

</template>

<script>

	export default {
	  	props: {
            search: {
                type: String,
                default: null
            }
        },
	  	data() {
		    return {
                fields_active: [
			        { key: 'country', label: 'Country', isSort: true},
			        { key: 'active', label: 'Active', isSort: true },
			        { key: 'completed', label: 'Completed', isSort: true },
			        { key: 'developing', label: 'Developing', isSort: true },
			        { key: 'terminated', label: 'Terminated', isSort: true  },
			    ],
		    	active: [
			    ],
			    sort: {
			    	field: 'country',
			    	type: 'asc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10',
            }
		},		
		mounted () {
		    this.selected = '10'
		},
		watch: {
			currentPage(val) {
				this.fetchProjectListingOverview(this.search)
			},
			selected(val){
				if(val){
					this.perPage = val
					this.fetchProjectListingOverview(this.search)
				}
			}
		},
		methods: {
		  	myRowClickHandler(item) {
		    	this.$router.push(`/admin/our-portfolio/project/${item.id}`);
		  	},
		  	fetchProjectListingOverview(search){
				this.$store
				.dispatch('project/fetchProjectListingOverview', {
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
					search: search
				})
				.then(response => {
					this.active = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			headClicked(key, isSort) {
				if(isSort.isSort == true){
					if(this.sort.field == key) {
						if(this.sort.type == 'asc') this.sort.type = 'desc'
						else this.sort.type = 'asc'
					} else {
						this.sort.field = key
						this.sort.type = 'desc'
					}								
					this.fetchProjectListingOverview(this.search)
				}
			},
		},
	};	

</script>