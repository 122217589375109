<template>
	<div class="w-15-percent-cl-1 w-20-percent-cl-4 w-15-percent-cl-5 w-15-percent-cl-6">
	    <b-table small :fields="fields_active" :items="active" responsive="lg" tbody-tr-class="size-14 table-row cursor-pointer" id="terminate-table" @head-clicked="headClicked" show-empty class="mb-3 cursor-pointer" @row-clicked="single_detail">

	    	<h2>Projects Overview</h2>
	    	<template #head(name)="data">
		        <span>{{ data.label }}</span>
		        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 30px;">
		        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
		        </span>
		    </template>
		    <template #head()="data">
				<span class="d-block text-center">{{ data.label }}</span>
			</template>
			<template #cell(name)="data">
				<span class="text-left maxThreeRow">{{ data.item.name }}</span>
			</template>			
			<template #cell(client_name)="data">
				<span class="text-center maxThreeRow">{{ data.item.client_name }}</span>
			</template>
			<template #cell(status)="data">
				<span class="d-block text-center">
					<span>Terminated</span>
				</span>
			</template>
			<template #cell(commencement_date)="data">
				<span class="d-block text-center">{{ data.item.commencement_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
			</template>
			<template #cell(completion_date)="data">
				<span class="d-block text-center">{{ data.item.completion_date | formatDatebyMoment("YYYY-MM-DD", "DD/MM/YY") }}</span>
			</template>
			<template #cell()="data">
				<span class="d-block text-center">{{ data.value }}</span>
			</template>
			<template #empty="scope">
				<template v-if="search">
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data found</h4>
				</template>
				<template v-else>
					<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
				</template>
		    </template>

		</b-table>
		<div v-if="this.total >= 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	aria-controls="terminate-table"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select name="" id="" v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>			  
		    </div>
		</div>
	</div>
</template>

<script>

	export default {
	  	props: {
            search: {
                type: String,
                default: null
            }
        },
	  	data() {
		    return {
                fields_active: [
                	{ key: 'name', label: 'Project Name' },
			        { key: 'country_name', label: 'Country' },			        
			        { key: 'status', label: 'Status' },
			        { key: 'client_name', label: 'Client' },
			        { key: 'project_value', label: 'Project Value (SGD)' },
			        { key: 'user_name', label: 'Project Owner' },
			        { key: 'commencement_date', label: 'Start Date' },
			        { key: 'completion_date', label: 'End Date' },
			    ],
		    	active: [
			    ],			    
			    sort: {
			    	field: 'id',
			    	type: 'desc'
			    },
			    perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10'  
            }
		},
		mounted () {
		    this.selected = '10'
		},
		watch: {
			currentPage(val){
				this.fetchProjectActivityList(this.search)
			},
			selected(val){
				if(val){
					this.perPage = val
					this.fetchProjectActivityList(this.search)
				}				
			},
		},
		methods: {
			fetchProjectActivityList(search = null){

				this.$store.dispatch('project/fetchProjectList', {
					limit: this.perPage,
					page: this.currentPage,
					sort: this.sort,
					search: search,
					filter: {
					  	"list_search": [
						    {
						      "field": "status",
						      "keyword": "3"
						    }
					  ],					  
					}	
				})
				.then(response => {
					this.active = response.data.data.data
					this.total = response.data.data.total
				})
				.catch((error) => {
					console.log(error)
				})
			},
			headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchProjectActivityList(this.search)
			},
			single_detail(item) {
		    	this.$router.push(`/admin/our-portfolio/project/${item.country_id}/edit/${item.id}`)
		  	},
		}
	};	

</script>

<style lang="scss">
</style>