<template>
	<section id="project" class="porfolio-breadcrumb">
		<b-row style="justify-content: space-between" class="mb-2 align-center">
			<b-col lg="7">
				<Breadcrumb :labels="labels" />
			</b-col>
			<b-col lg="5">
				<div class="icon-search-wrapper ml-auto" style="max-width: 346px; width: 100%;">
					<b-form-group class="mb-0">
						<b-input-group class="input-group-merge">
							<b-input-group-prepend is-text>
								<feather-icon icon="SearchIcon" size="17" />
							</b-input-group-prepend>
							<b-form-input placeholder="Search" @input="searchProject" />
						</b-input-group>
					</b-form-group>
				</div>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="12">
				<div class="btn-action text-right mb-1">

					<template v-if="export_num > -1">
						<b-button type="button" variant="primary" class="px-1 mr-10 py-1 h-auto size-18"
							@click="exportData(export_num)">Export Projects</b-button>
					</template>


					<template v-if="(userData && permission.create)">

						<b-button type="button" variant="primary" class="py-1 h-auto size-18" @click="addProject">Add
							Project</b-button>

					</template>
					<template v-else>
						<b-button type="button" variant="primary" class="py-1 h-auto size-18 btn-disabled-gray" disabled>Add
							Project</b-button>
					</template>
				</div>
				<b-tabs card class="caption-add min-120" v-model="tabIndex">
					<b-tab title="Overview" class="overview-tab table-custom">
						<div class="content-tab info-content">
							<Overview ref="tab_0" :search="search" />
						</div>
					</b-tab>
					<b-tab title="Active" class="active-tab table-custom">
						<div class="content-tab info-content">
							<Active ref="tab_1" :search="search" />
						</div>
					</b-tab>
					<b-tab title="Completed" class="completed-tab table-custom">
						<div class="content-tab info-content">
							<Completed ref="tab_2" :search="search" />
						</div>
					</b-tab>
					<b-tab title="Developing" class="developing-tab table-custom">
						<div class="content-tab info-content">
							<Developing ref="tab_3" :search="search" />
						</div>
					</b-tab>
					<b-tab title="Terminated" class="table-custom">
						<div class="content-tab info-content">
							<Terminated ref="tab_4" :search="search" />
						</div>
					</b-tab>
				</b-tabs>

			</b-col>
		</b-row>
	</section>
</template>
<script>
import Breadcrumb from '../partial/Breadcrumb';
import Overview from './Overview';
import Active from './Active';
import Completed from './Completed';
import Developing from './Developing';
import Terminated from './Terminated';
import globalAdmin from '../model/globalAdmin';
import {
	mapActions,
	mapState
} from 'vuex'
export default {
	mixins: [globalAdmin],
	components: {
		Breadcrumb,
		Overview,
		Active,
		Completed,
		Developing,
		Terminated,
	},
	data() {
		return {
			userData: JSON.parse(localStorage.getItem('userData')),
			activity: {},
			permission: [],
			labels: { "our-portfolio-project": "" },
			search: null,
			timer: null,
			tabIndex: 0,
			export_num: -1,
			filter: {}
		}
	},
	watch: {
		search(val) {
			this.loadDataToTab(val)
		},
		tabIndex: function (val) {
			this.loadDataToTab(this.search)
			this.export_num = parseInt(val) - 1
		},
		"$route": function (val) {
			if (val) {
				if (this.$route && this.$route.query && this.$route.query.tab) this.tabIndex = Number(this.$route.query.tab)
			}
		},
		permissions: function (val) {
			this.permission = val
			// console.log(this.permission,'9999999999999')
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.loadDataToTab(this.search)
		})
	},
	created() {
		this.permission = this.checkPermissionOurPortfolio(this.userData, "Project", this.$route.params.project_id, "Project") ?? []
	},
	computed: mapState({
		permissions: state => state.auth.permissionOurPortfolio,
	}),
	methods: {
		...mapActions({
			checkPermisionOurPortfolio: 'auth/checkPermisionOurPortfolio',
		}),
		addProject() {
			this.$router.push(`/admin/our-portfolio/project/add`)
		},
		searchProject(val) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.search = val
			}, 200);
		},
		exportData(item) {
			if (item < 0) {
				this.filter = ''
			}
			else {
				this.filter = {
					"list_search": [{
						"field": "status", "keyword": item
					}]
				}
			}
			this.$store.dispatch('project/exportCsv', {
				filter: this.filter
			}).then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'Projects.csv') //or any other extension
				document.body.appendChild(link)
				link.click()
			})
				.catch((error) => {
					console.log(error)
				})
		},
		loadDataToTab(val) {
			// pagging to page 2 then qua tab khác, quay lại tab cũ paging sẽ set lại page 1
			this.$refs[`tab_${this.tabIndex}`].currentPage = 1
			if (this.tabIndex == 0) {
				this.$refs[`tab_0`].fetchProjectListingOverview(val)
				return;
			}
			this.$refs[`tab_${this.tabIndex}`].fetchProjectActivityList(val)
		}
	}
};

</script>

<style lang="scss">	@import "@/assets/scss/_ite_tab_table.scss";
</style>

